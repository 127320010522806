/*********************************/
/*         Testimonial           */
/*===============================*/

.tns-nav {
    @apply text-center mt-3;
    button {
        @apply rounded-[3px] bg-indigo-600/30 duration-500 border-0 m-1 p-[5px];
        &.tns-nav-active {
            @apply bg-indigo-600 rotate-[45deg];
        }
    }
}

/* Tns control */
.tns-controls {
    button[data-controls="prev"],
    button[data-controls="next"] {
        @apply absolute top-2/4 -translate-y-2/4 text-base size-8 rounded-full bg-white dark:bg-slate-900 text-dark dark:text-white border-0 duration-500 z-10 shadow-md dark:shadow-gray-800;

        &:hover {
            @apply bg-indigo-600 text-white;
        }
    }
    button[data-controls="prev"] {
        @apply start-0;
    }
    button[data-controls="next"] {
        @apply end-0;
    }
}


//Smoth Slider
$animationSpeed: 120s;
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-360px * 6))}
}
.slider {
	&:after {
		transform: rotateZ(360deg);
	}
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		width: calc(360px * 20);
	}
}

/* RTL */
html[dir="rtl"]{
    .tns-inner, .tns-nav, .slider, .slider2, .swiper-slider-hero {
        direction: ltr !important;
    }

    .tns-visually-hidden {
        left: 0 !important;
        visibility: hidden;
    }
}