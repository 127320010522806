/* Template Name: Techwind - Tailwind CSS Multipurpose Landing & Admin Dashboard Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 2.2.0
   Created: May 2022
   File: Main Css File
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   .html {
      overflow-x: hidden;
   }
}

@layer components {
  .container {
    @apply md:max-w-[840px] lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl;
  }
}

// FONTS
@import "custom/fonts";

// general
@import "custom/general";

// structure
@import "custom/structure/topnav";
@import "custom/structure/footer";

// pages
@import "custom/pages/helper";
@import "custom/pages/hero";
@import "custom/pages/countdown";
@import "custom/pages/portfolio";
@import "custom/pages/contact";

// Plugins
@import "custom/plugins/swiper-slider";
@import "custom/plugins/testi";
@import "custom/plugins/choices";
@import "custom/plugins/datepicker";

.scroll-x::-webkit-scrollbar {
   @apply w-0 h-0 sm:w-2 sm:h-2;
 }

 .scroller {
   @apply w-full;
 }

 .scroller__inner {
   padding-block: 48px;
   @apply flex flex-wrap gap-24 items-center;
 }

 .scroller[data-animated="true"] {
   @apply overflow-hidden;
 }

 .scroller[data-animated="true"] .scroller__inner {
   animation: scroll var(--_animation-duration, 40s)
     var(--_animation-direction, forwards) linear infinite;
   @apply w-max flex-wrap;
 }

 .scroller[data-direction="right"] {
   --_animation-direction: reverse;
 }

 .scroller[data-direction="left"] {
   --_animation-direction: forwards;
 }

 .scroller[data-speed="fast"] {
   --_animation-duration: 20s;
 }

 .scroller[data-speed="slow"] {
   --_animation-duration: 60s;
 }

 @keyframes scroll {
   to {
     transform: translate(calc(-50% - 0.5rem));
   }
 }
