//
// _fonts.scss
//


// @import url('https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

@font-face {
  font-family: 'Alex Brush';
  src: url('/assets/fonts/AlexBrush-Regular.woff2') format('woff2'),
      url('/assets/fonts/AlexBrush-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-Bold.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-ExtraBold.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-ExtraBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-Italic.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-Medium.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-MediumItalic.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-Regular.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('/assets/fonts/EBGaramond-SemiBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/EBGaramond-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Kaushan Script';
  src: url('/assets/fonts/KaushanScript-Regular.woff2') format('woff2'),
      url('/assets/fonts/KaushanScript-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Black.woff2') format('woff2'),
      url('/assets/fonts/Nunito-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-BlackItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Bold.woff2') format('woff2'),
      url('/assets/fonts/Nunito-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-ExtraBold.woff2') format('woff2'),
      url('/assets/fonts/Nunito-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-ExtraBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-ExtraLight.woff2') format('woff2'),
      url('/assets/fonts/Nunito-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-ExtraLightItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Italic.woff2') format('woff2'),
      url('/assets/fonts/Nunito-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Light.woff2') format('woff2'),
      url('/assets/fonts/Nunito-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-LightItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Medium.woff2') format('woff2'),
      url('/assets/fonts/Nunito-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-MediumItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Regular.woff2') format('woff2'),
      url('/assets/fonts/Nunito-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/Nunito-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-SemiBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/Nunito-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-BlackItalic.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-Black.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-Bold.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-ExtraBold.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-ExtraBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-ExtraLight.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-ExtraLightItalic.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-Italic.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-Light.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-LightItalic.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-Medium.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-MediumItalic.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-Regular.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-SemiBoldItalic.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-Thin.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Work Sans';
  src: url('/assets/fonts/WorkSans-ThinItalic.woff2') format('woff2'),
      url('/assets/fonts/WorkSans-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
