/*********************************/
/*             Home            */
/*===============================*/
/* background-effect For Job Hero */
.background-effect {
    .circles {
        li {
            @apply absolute block -bottom-[150px] bg-indigo-600/30;
            animation: animate 25s linear infinite;

            &.brand-img {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10) {
                    @apply bg-transparent;
                }
            }
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10) {
                @apply size-12;
            }
            &:nth-child(1) {
                @apply start-1/4;
                animation-delay: 0s;
            }
        
            &:nth-child(2) {
                @apply start-[10%];
                animation-delay: 2s;
                animation-duration: 12s;
            }
        
            &:nth-child(3) {
                @apply start-[70%];
                animation-delay: 4s;
            }
        
            &:nth-child(4) {
                @apply start-[40%];
                animation-delay: 0s;
                animation-duration: 18s;
            }
        
            &:nth-child(5) {
                @apply start-[65%];
                animation-delay: 0s;
            }
        
            &:nth-child(6) {
                @apply start-3/4;
                animation-delay: 3s;
            }
        
            &:nth-child(7) {
                @apply start-[35%];
                animation-delay: 7s;
            }
        
            &:nth-child(8) {
                @apply start-1/2;
                animation-delay: 15s;
                animation-duration: 45s;
            }
        
            &:nth-child(9) {
                @apply start-[20%];
                animation-delay: 2s;
                animation-duration: 35s;
            }
        
            &:nth-child(10) {
                @apply start-[85%];
                animation-delay: 0s;
                animation-duration: 11s;
            }
        }
    }
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 10px;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
    }
}

/* Kenburn Effect for Business Demo */
.image-wrap {
    animation: 100s ppb_kenburns linear infinite alternate;
}

@keyframes move {
    0% {
        transform-origin: bottom;
        transform: scale(1);
    }
    100% {
        transform: scale(1.4);
    }
}

@keyframes ppb_kenburns {
    0% {
        transform: scale(1.3) translate(-10%, 10%);
    }
    25% {
        transform: scale(1) translate(0, 0);
    }
    50% {
        transform: scale(1.3) translate(10%, 10%);
    }
    75% {
        transform: scale(1) translate(0, 0);
    }
    100% {
        transform: scale(1.3) translate(-10%, 10%);
    }
}

//Spa Home
.spa-css {
    @apply font-para-worksans #{!important};

    #topnav {
        .navigation-menu {
            > li {
                > a {
                    @apply text-[14px] font-medium px-[12px] #{!important};
                }

                .submenu {
                    li {
                        a {
                            @apply text-[12px] font-medium #{!important};
                        }
                    }
                    &.megamenu {
                        li {
                            .megamenu-head {
                                @apply font-medium #{!important};
                            }
                        }
                    }
                }
            }
        }
    }
}